<template>
  <div class="news">
    <div class="img-box pr">
      <el-image class="wi100 hi100" :src="bg" fit="cover"></el-image>
      <div class="ban-dec uf uf-ver uf-pc">
        <div class="content-box">
          <div class="tit fwb tac">应用示范</div>
        </div>
      </div>
    </div>
    <div class="content-box pt-3 pb-2">
      <div class="uf uf-pc">
        <div class="box-tit f18 fwb"><span>核心示范企业</span></div>
      </div>
      <div class="company-box uf uf-pj fwrap-w">
        <div class="c-list uf uf-ac mb-3" v-for="(item, index) in company" :key="index">
          <el-image :src="item.img" fit="cover" style="width: 60px;height: 60px"></el-image>
          <div class="fwb tac f22 ml-3">{{ item.name }}</div>
        </div>
      </div>
<!--      <div class="uf uf-pc mt-5">-->
<!--        <div class="box-tit f18 fwb"><span>明星示范项目</span></div>-->
<!--      </div>-->
<!--      <div class="pro-box mb-4">-->
<!--        <el-carousel :interval="4000" type="card" height="400px">-->
<!--          <el-carousel-item v-for="item in 6" :key="item">-->
<!--            <h3 class="medium">{{ item }}</h3>-->
<!--          </el-carousel-item>-->
<!--        </el-carousel>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'news',
  data () {
    return {
      bg: require('../../assets/img/application.jpg'),
      company: [
        {name: '山东某某智能制造有限公司', img: require('@/assets/img/c-logo/01.jpg')},
        {name: '济南某某汽车有限公司', img: require('@/assets/img/c-logo/02.jpg')},
        {name: '郑州某某服装设计有限公司', img: require('@/assets/img/c-logo/03.jpg')},
        {name: '天津某某人工智能有限公司', img: require('@/assets/img/c-logo/04.jpg')},
        {name: '上海某某集团有限公司', img: require('@/assets/img/c-logo/05.jpg')},
        {name: '广州某某互联网有限公司', img: require('@/assets/img/c-logo/06.jpg')}
      ]
    }
  },
  methods: {
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    }
  },
}
</script>
<!--transform: skewX(-30deg);-->
<style scoped lang="scss">
.news {
  margin-top: -50px;
}
.img-box {
  width: 100vw;
  height: 26vw;
}
.tit {
  font-size: 4vw;
  padding-top: 4vw;
  letter-spacing: 9px;
  color: white;
}
.tit {
  font-size: 4vw;
  padding-top: 4vw;
  letter-spacing: 9px;
  color: white;
  text-shadow: 0 0 20px rgba(0,0,0,.3);
}
.content-box {
  width: 1325px;
  margin: 0 auto;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
.company-box {
  .c-list {
    padding: 20px;
    width: 46%;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0 0 5px #b988ff;
    border: 1px solid transparent;
    transition: all .3s ease;
    cursor: pointer;
    &:hover {
      border: 1px solid #04cb94;
    }
  }
}
.n-list {
  padding: 30px 20px;
  background: #eeeeee;
  margin-bottom: 20px;
  border-radius: 10px;
  transition: all .3s ease;
  &:hover {
    box-shadow: 0 0 20px rgba(0,0,0,.2);
  }
}
.box-tit {
  width: 180px;
  line-height: 46px;
  color: #04cb94;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 20px;
  border-bottom: 1px solid #3f3f3f;

}
.ban-dec {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .dec-box {
    text-align: center;
    color: #ffffff;
    padding: 30px;
    .dec-tit {
      display: inline-block;
      background: transparent;
      font-size: 5vw;
      letter-spacing: 5px;
      margin-bottom: 1vw;
      text-shadow: 0 0 30px rgba(0,0,0,.4);
    }

    .dec-dec {
      line-height: 35px;
      opacity: .7;
    }
  }
}
</style>
